import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const ThanksPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Thanks" />
      <div className="container max-w-3xl mx-auto px-4 py-4 sm:py-10">
        <div className="content">
          <h1>Thanks!</h1>
          <p>
            Thank you for getting in touch, we have received your message and
            will contact you shortly.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ThanksPage
